
.h3 {
  font-weight: bold;
  font-size: 30px;
  color: #2d3954;;
}

.h3 {
  font-style:normal;
  font-size: 30px;
  color: #2d3954;;
}
.h4 {
  font-weight: bold;
  font-size: 18px;
  color: #333;
  margin-top: 10px;

}

.h4 {
  font-size: 14px;
  color: #000000;
}
/* Small screens */
@media (max-width: 576px) {
  .recent .text {
    padding: 15px;
  }
  .recent .category span {
    padding: 2px 10px;
    font-size: 11px;
  }
  .recent .category i {
    font-size: 16px;
    padding: 0 10px;
  }
  .recent p {
    font-size: 14px;
  }
  .recent .button {
    padding: 5px 15px;
  }
  .heading{
    text-align: center;
  }
}

/* Medium screens */
@media (min-width: 577px) and (max-width: 768px) {
  .recent .text {
    padding: 20px;
  }
  .recent .category span {
    padding: 3px 12px;
    font-size: 12px;
  }
  .recent .category i {
    font-size: 18px;
    padding: 0 12px;
  }
  .recent p {
    font-size: 15px;
  }
  .recent .button {
    padding: 7px 20px;
  }
  .heading{
    text-align: center;
  }
}

/* Large screens */
@media (min-width: 768px) {
  .recent .text {
    padding: 30px;
    
  }
  .recent .category span {
    padding: 4px 15px;
    font-size: 13px;
  }
  .recent .category i {
    font-size: 20px;
    padding: 0 15px;
  }
  .recent p {
    font-size: 16px;
  }
  .recent .button {
    padding: 10px 30px;
  }
  .heading{
    text-align: center;
  }
}
