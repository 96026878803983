.hero {
  background-image: url("../../../../public/banner.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 90vh;
  width: 100%;
}

.hero .container {
  padding-top: 15%;
}

.hero h1 {
  color: #fff;
  font-size: 60px;
}

.hero p {
  color: #fff;
  opacity: 0.8;
}

form {
  background-color: #fff;
  border-radius: 5px;
  margin-top: 50px;
  padding: 0 20px;
}

form input {
  padding: 10px;
  width: 100%;
  border: 1px solid rgba(128, 128, 128, 0.2);
  margin-top: 5px;
  border-radius: 5px;
}

form span {
  font-size: 14px;
  color: grey;
}

input::placeholder {
  font-size: 17px;
  color: black;
}

form .box {
  padding: 15px;
  border-left: 1px solid rgba(128, 128, 128, 0.2);
}

form .box:nth-child(1) {
  border-left: none;
}

form h4 {
  font-weight: 500;
}

/* Small screens */
@media (max-width: 576px) {
  .hero {
    height: auto;
    min-height: 50vh;
  }
  .hero .container {
    padding-top: 20%;
  }
  form {
    margin-top: 30px;
  }
  form .box {
    border-left: none;
    padding: 10px;
  }
  form input {
    width: 100%;
  }
  form h4 {
    font-size: 18px;
  }
  input::placeholder {
    font-size: 14px;
  }
}

/* Medium screens */
@media (min-width: 577px) and (max-width: 992px) {
  .hero .container {
    padding-top: 25%;
  }
  form {
    display: flex;
    flex-wrap: wrap;
  }
  form .box {
    border-left: none;
    padding: 10px;
  }
  form input {
    width: 100%;
  }
  form h4 {
    font-size: 18px;
  }
  input::placeholder {
    font-size: 14px;
  }
}

/* Large screens */
@media (min-width: 993px) {
  .hero .container {
    padding-top: 15%;
  }
  form {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  form .box {
    border-left: 1px solid rgba(128, 128, 128, 0.2);
    padding: 15px;
  }
  form input {
    width: calc(50% - 15px);
  }
  form h4 {
    font-size: 24px;
  }
  input::placeholder {
    font-size: 17px;
  }
}
