.featured .box {
  box-shadow: 0 0 20px 0 rgba(112, 121, 138, 0.18);
  border-radius: 6px;
  text-align: center;
  padding: 20px;
  cursor: pointer;
}

/* Small screens */
@media (max-width: 576px) {
  .featured .box {
    padding: 10px;
    border-radius: 6px;
  text-align: center;
  padding: 20px;
  cursor: pointer;
  }
  .featured img {
    width: 40px;
    height: 40px;
    margin: auto;
  }
}

/* Medium screens */
@media (min-width: 577px) and (max-width: 768px) {
  .featured .box {
    padding: 15px;
    border-radius: 6px;
    text-align: center;
    padding: 20px;
    cursor: pointer;
  }
  .featured img {
    width: 50px;
    height: 50px;
    margin:auto;
  }
}

/* Large screens */
@media (min-width: 769px) {
  .featured .box {
    padding: 30px;
    border-radius: 6px;
    text-align: center;
    padding: 20px;
    cursor: pointer;
  }
  .featured img {
    width: 65px;
    height: 65px;
    margin: auto;
  }
}
